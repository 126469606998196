import { memo, useState } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import { SealLogo } from "common/core/logo";
import { Heading, Paragraph } from "common/core/typography";
import Link from "common/core/link";
import { Card } from "common/core/card";
import Icon from "common/core/icon";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import Button from "common/core/button";
import { useLazyQuery } from "util/graphql/query";
import { hardNavigateTo } from "util/navigation";
import { PROOF_WORKRAMP_REGISTER_URL } from "constants/support";
import ProofAcademyImage from "assets/images/proof_academy.png";
import NotaryProfileApprovalImage from "assets/images/notary_profile_approval.svg";
import type { NotaryActivationStatus, NotaryCapacityType } from "graphql_globals";

import WrInstantAuthQuery, {
  type WorkrampInstantAuthUrl_notaryProfile_NotaryProfile as NotaryProfile,
} from "../../profile_wizard/section/wr_instant_auth_query.graphql";
import { STEP_CONTENT, type Step } from "../sidebar";
import Styles from "./index.module.scss";

type NotaryProfileWithCapacity = {
  id: string;
  capacities: {
    type: NotaryCapacityType;
    status: NotaryActivationStatus;
  }[];
};

type Props = {
  steps: readonly Step[];
  notaryProfile: NotaryProfileWithCapacity;
  finished?: boolean;
};

type RenderStepsProps = {
  steps: readonly Step[];
  firstIncompleteStepId?: keyof typeof STEP_CONTENT;
  stepCounter: number;
  overview?: boolean;
};

type RenderReviewStepsProps = RenderStepsProps & {
  notaryProfile: NotaryProfileWithCapacity;
};

export function RenderSteps({
  steps,
  firstIncompleteStepId,
  stepCounter,
  overview,
}: RenderStepsProps) {
  return (
    <ol className={Styles.stepsList}>
      {steps.map(({ id, completed, isActive }, index) => {
        const content = STEP_CONTENT[id];
        const isActiveStep = id === firstIncompleteStepId || isActive;

        const cx = classNames(Styles.stepItem, {
          [Styles.completed]: completed,
          [Styles.active]: isActiveStep,
        });

        return (
          <li key={id} id={completed ? `${id}-completed` : id} className={cx}>
            <span className={Styles.circleContent}>
              {completed ? (
                <Icon className={Styles.checkmark} name="tick" />
              ) : (
                <span className={Styles.stepNumber}>{stepCounter + index}</span>
              )}
            </span>
            <div className={Styles.stepContent}>
              {completed ? (
                <Link
                  underlined={false}
                  black
                  to={`/settings/notary/onboarding/${id}`}
                  className={Styles.link}
                >
                  {content}
                </Link>
              ) : isActiveStep ? (
                <div className={Styles.activeStep}>
                  {content}
                  {overview && (
                    <ButtonStyledLink
                      buttonColor="action"
                      variant="primary"
                      to={`/settings/notary/onboarding/${id}`}
                      className={Styles.activeButton}
                      automationId="begin-step"
                    >
                      <FormattedMessage
                        id="01a224a6-3385-4de4-be11-428fe70b8aa5"
                        defaultMessage="Begin"
                      />
                    </ButtonStyledLink>
                  )}
                </div>
              ) : (
                <span className={Styles.incompleteStep}>{content}</span>
              )}
            </div>
          </li>
        );
      })}
    </ol>
  );
}

function RenderReviewSteps({
  steps,
  firstIncompleteStepId,
  stepCounter,
  notaryProfile,
}: RenderReviewStepsProps) {
  const [trainingLinkClicked, setTrainingLinkClicked] = useState(false);
  const [fetchTrainingUrl, { loading }] = useLazyQuery(WrInstantAuthQuery, {
    fetchPolicy: "no-cache",
  });

  const handleProofAcademyClick = () => {
    fetchTrainingUrl({
      variables: { notaryProfileId: notaryProfile.id },
    }).then((result) => {
      const notaryProfileWithWr = result.data?.notaryProfile as NotaryProfile;
      const trainingUrl = notaryProfileWithWr.workrampInstantAuthUrl || PROOF_WORKRAMP_REGISTER_URL;
      hardNavigateTo(trainingUrl, { newTab: true });
      setTrainingLinkClicked(true);
    });
  };

  const renderProofAcademyButton = (
    completed: boolean | undefined,
    isActive: boolean | undefined,
  ) => {
    if (completed) {
      return (
        <Button
          buttonColor="action"
          variant="secondary"
          isLoading={loading}
          onClick={handleProofAcademyClick}
        >
          <FormattedMessage
            id="aeadd69b-1c8c-468a-bff3-5c40e3d79afe"
            defaultMessage="Go to Proof Academy"
          />
        </Button>
      );
    }

    if (isActive) {
      return (
        <Button
          buttonColor="action"
          variant="primary"
          withIcon={{ placement: "left", name: "new-window" }}
          disabled={trainingLinkClicked}
          isLoading={loading}
          onClick={handleProofAcademyClick}
        >
          <FormattedMessage
            id="aeadd69b-1c8c-468a-bff3-5c40e3d79afe"
            defaultMessage="Begin training"
          />
        </Button>
      );
    }

    return null;
  };

  const renderAdditionalCard = (id: string) => {
    switch (id) {
      case "ProofAcademy":
        return (
          <li key={`${id}-card`} className={Styles.stepCard}>
            <Paragraph size="large" className={Styles.cardParagraph}>
              <FormattedMessage
                id="d81da11b-4263-46a6-be81-a5eb532de25f"
                defaultMessage="Start platform training by registering for a Proof Academy account. Complete the required certification to streamline your onboarding."
              />
            </Paragraph>
            <img src={ProofAcademyImage} alt="" className={Styles.academyImage} />
          </li>
        );
      case "ProfileApproval":
        return (
          <li key={`${id}-card`} className={Styles.stepCard}>
            <Paragraph size="large" className={Styles.cardParagraph}>
              <FormattedMessage
                id="de7359ff-d11f-46d6-9199-2ab87ef10b6c"
                defaultMessage="An Onboarding Specialist will be in touch in 2-5 business days with next steps. To avoid delays, please avoid contacting before then."
              />
            </Paragraph>
            <img src={NotaryProfileApprovalImage} alt="" className={Styles.approvalImage} />
          </li>
        );
      default:
        return null;
    }
  };

  return (
    <ol className={Styles.stepsList}>
      {steps.map(({ id, completed, isActive }, index) => {
        const content = STEP_CONTENT[id];
        const isActiveStep = id === firstIncompleteStepId || isActive;

        const cx = classNames(Styles.stepItem, {
          [Styles.completed]: completed,
          [Styles.active]: isActiveStep,
        });

        return (
          <>
            <li key={id} className={cx}>
              <span className={Styles.circleContent}>
                {completed ? (
                  <Icon className={Styles.checkmark} name="tick" />
                ) : (
                  <span className={Styles.stepNumber}>{stepCounter + index}</span>
                )}
              </span>
              <div className={Styles.stepContent}>
                {completed || isActiveStep ? (
                  <div className={Styles.activeStep}>
                    {content}
                    {id === "ProofAcademy" && renderProofAcademyButton(completed, isActiveStep)}
                  </div>
                ) : (
                  <span className={Styles.incompleteStep}>{content}</span>
                )}
              </div>
            </li>
            {isActiveStep && renderAdditionalCard(id)}
          </>
        );
      })}
    </ol>
  );
}

export const ProfileSteps = [
  "PersonalInfo",
  "CommissionDetails",
  "InsuranceDetails",
  "DigitalCertificate",
  "SignatureAndSeal",
  "PayoutOptions",
] as const;

export const ProofCertSteps = ["IdentityVerification", "ProofCertificate"] as const;

export const ProfileReviewSteps = ["SubmitProfile", "ProofAcademy", "ProfileApproval"] as const;

export function filterSteps(steps: readonly Step[], ids: readonly (keyof typeof STEP_CONTENT)[]) {
  return steps.filter((step) => ids.includes(step.id));
}

function OnboardingOverview({ steps, notaryProfile, finished }: Props) {
  const firstIncompleteStep = steps.find((step) => !step.completed);
  const firstIncompleteStepId = firstIncompleteStep?.id;
  const profileSteps = filterSteps(steps, ProfileSteps);
  const proofCertificateSteps = filterSteps(steps, ProofCertSteps);
  const profileReviewSteps = filterSteps(steps, ProfileReviewSteps);

  return (
    <div className={Styles.main} data-automation-id="overview-container">
      <OverviewHeader finished={finished} />
      <Card>
        <div>
          <Heading level="h2" textStyle="headingFive" textAlign="left">
            <FormattedMessage
              id="1a5c3b5b-5b9d-4b1b-9f7f-3c3d6d7f2b5d"
              defaultMessage="Create your notary profile"
            />
          </Heading>
          <div className={Styles.subHeading}>
            <FormattedMessage
              id="b1d7f3b0-0b6b-4f8b-8f8d-1f4b4d4f9f3e"
              defaultMessage="Provide your personal information and notary details"
            />
          </div>
          <RenderSteps
            steps={profileSteps}
            firstIncompleteStepId={firstIncompleteStepId}
            stepCounter={1}
            overview
          />
        </div>
      </Card>
      {proofCertificateSteps.length > 0 && (
        <Card>
          <div>
            <Heading level="h2" textStyle="headingFive" textAlign="left">
              <FormattedMessage
                id="b1d7f3b0-0b6b-4f8b-8f8d-1f4b4d4f9f3e"
                defaultMessage="Generate your Proof Digital Certificate"
              />
            </Heading>
            <div className={Styles.subHeading}>
              <FormattedMessage
                id="28fac965-f2f5-487f-b5ea-1ae8590a8bd1"
                defaultMessage="Your certificate ensures secure transactions on the platform"
              />
            </div>
            <RenderSteps
              steps={proofCertificateSteps}
              firstIncompleteStepId={firstIncompleteStepId}
              stepCounter={profileSteps.length + 1}
              overview
            />
          </div>
        </Card>
      )}
      <Card>
        <div>
          <Heading level="h2" textStyle="headingFive" textAlign="left">
            <FormattedMessage
              id="f93721fd-71f0-45c9-8f7e-0b21608032ac"
              defaultMessage="Submit profile for review by Proof"
            />
          </Heading>
          <div className={Styles.subHeading}>
            <FormattedMessage
              id="1b54cf58-50ae-4ef2-8fdf-956737946be1"
              defaultMessage="Please allow 2-5 business days for initial review by Proof "
            />
          </div>
          <RenderReviewSteps
            steps={profileReviewSteps}
            firstIncompleteStepId={firstIncompleteStepId}
            stepCounter={profileSteps.length + proofCertificateSteps.length + 1}
            notaryProfile={notaryProfile}
          />
        </div>
      </Card>
    </div>
  );
}

function OverviewHeader({ finished }: { finished?: boolean }) {
  return (
    <div>
      {finished ? (
        <div>
          <Heading className={Styles.headerEmoji} level="h1" aria-hidden="true" textStyle="hero">
            🎉
          </Heading>
          <Heading
            className={Styles.finishedHeader}
            level="h2"
            textStyle="headingFour"
            textAlign="left"
          >
            <FormattedMessage
              id="d4b87bb2-b683-43a8-8c97-e5ae155170ea"
              defaultMessage="Profile submitted!"
            />
          </Heading>
          <Paragraph className={Styles.tagLine}>
            <FormattedMessage
              id="c6772df5-b888-4c39-961a-01d1bb660fa3"
              defaultMessage="We have received your profile and will be in touch soon with next steps"
            />
          </Paragraph>
        </div>
      ) : (
        <>
          <SealLogo className={Styles.logo} />
          <Heading level="h1" textStyle="headingFour" textAlign="left">
            <FormattedMessage
              id="7407b044-56af-47d4-90c9-8dadae50cfe1"
              defaultMessage="Your onboarding process"
            />
          </Heading>
          <Paragraph size="large" className={Styles.paragraph}>
            <FormattedMessage
              id="a52910a1-6e38-49f8-a571-89b74f898737"
              defaultMessage="Complete all steps, and our team will review your profile and email you the next steps. This process takes 2-5 days to maintain platform security. For questions, contact {onboardingLink}"
              values={{
                onboardingLink: (
                  <Link href="mailto:notaryonboarding@proof.com">notaryonboarding@proof.com</Link>
                ),
              }}
            />
          </Paragraph>
        </>
      )}
    </div>
  );
}

export default memo(OnboardingOverview);
