import { FormattedMessage, useIntl } from "react-intl";
import { Navigate } from "react-router-dom";

import { NotaryComplianceStatuses, NotaryOnboardingStatuses } from "graphql_globals";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";
import { useActiveOrganization } from "common/account/active_organization";
import { isNotaryNST } from "common/notary/capacity";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { useFeatureFlag } from "common/feature_gating";
import { TRANSACTION_PATH } from "util/routes";
import { HIDE_GET_STARTED } from "constants/feature_gates";
import { useOnboardingV2 } from "util/feature_detection";

import { Root } from "../common/root";
import { Header } from "../common/header";
import { ToolsSection, BusinessWorkflows, ResourcesSection, NSTSection } from "../common/section";
import NotaryIndividualContractorGetStarted from "./notary_individual_contractor";
import BusinessGetStartedQuery, {
  type BusinessGetStarted_viewer as Viewer,
  type BusinessGetStarted_organization_Organization as Organization,
} from "./business_get_started_query.graphql";
import { MobileDeviceGetStarted } from "./mobile_device";
import { ProofAcademyItem, SendHowToItem } from "./common";
import { NotaryToolsHowToItem } from "../common/how_to_item";
import { ProfileReviewCard } from "../common/task_item";
import Styles from "./index.module.scss";

type Props = {
  viewer: Viewer;
  organization: Organization | null;
};

function LoadedBusinessGetStarted({ viewer, organization }: Props) {
  const user = viewer.user!;
  const { notaryProfile } = user;
  const isNst = isNotaryNST(notaryProfile);
  const onboardingV2 = useOnboardingV2();
  const isProfileInReview =
    notaryProfile?.complianceStatus === NotaryComplianceStatuses.NEEDS_REVIEW &&
    notaryProfile.onboardingStatus === NotaryOnboardingStatuses.COMPLETED;

  if (!organization) {
    return <Navigate to="/" />;
  }

  if (isNst) {
    return <NotaryIndividualContractorGetStarted organization={organization} viewer={viewer} />;
  }

  const featureList = organization.featureList;

  return (
    <div>
      <Header organizationId={organization.id} />
      <Root>
        {onboardingV2 && isProfileInReview && (
          <div className={Styles.topMargin}>
            <ProfileReviewCard />
          </div>
        )}
        {notaryProfile ? (
          <NSTSection
            title={
              <FormattedMessage
                id="4eadbade-86f7-4f09-a585-a25b750d7703"
                defaultMessage="Get started on Proof"
              />
            }
          >
            <ProofAcademyItem notaryProfile={notaryProfile} />
            <SendHowToItem />
            <NotaryToolsHowToItem notaryProfile={notaryProfile} />
          </NSTSection>
        ) : (
          <BusinessWorkflows />
        )}

        <ToolsSection featureList={featureList} />
        <ResourcesSection />
      </Root>
    </div>
  );
}

function DesktopGetStarted() {
  const [activeOrganizationId] = useActiveOrganization();
  const hideGetStarted = useFeatureFlag(HIDE_GET_STARTED);

  const { data, loading } = useQuery(BusinessGetStartedQuery, {
    variables: {
      orgId: activeOrganizationId || "skip",
      includeOrg: Boolean(activeOrganizationId),
    },
  });

  if (hideGetStarted || !activeOrganizationId) {
    return <Navigate to={TRANSACTION_PATH} replace />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  const { organization, viewer } = data!;

  if (organization && organization.__typename !== "Organization") {
    throw new Error(`Expected organization, found ${organization.__typename}`);
  }

  return <LoadedBusinessGetStarted viewer={viewer} organization={organization} />;
}

export function GetStarted({ showMobileDeviceContent }: { showMobileDeviceContent: boolean }) {
  const intl = useIntl();
  useA11y().useDocumentEntitler({ title: intl.formatMessage(useDocumentTitles().getStarted) });
  return showMobileDeviceContent ? <MobileDeviceGetStarted /> : <DesktopGetStarted />;
}
