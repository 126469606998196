import "common/notary/profile_wizard/section/index.scss";

import { memo, useMemo, type ReactNode } from "react";

import StateRequiredEducation, {
  stateEducationSection,
} from "common/notary/profile_wizard/section/state_required_education";
import type { MaybeUpdateUserFn } from "common/notary/onboarding";
import CommissionDetails, {
  commissionDetailsSection,
} from "common/notary/profile_wizard/section/commission_details";
import InsuranceDetails, {
  insuranceDetailsSection,
} from "common/notary/profile_wizard/section/insurance_details";
import DigitalCertificate, {
  certificateSection,
} from "common/notary/profile_wizard/section/digital_certificate";
import SignatureAndSeal, {
  sigAndSealSection,
} from "common/notary/profile_wizard/section/signature_and_seal";
import PayoutOptions, { payoutSection } from "common/notary/profile_wizard/section/payout";
import PersonalInformationSection, {
  personalInfoSection,
} from "common/notary/profile_wizard/section/personal_information";
import Footer from "common/notary/onboarding/footer";
import IdentityVerification, {
  identityVerificationSection,
} from "common/notary/profile_wizard/section/identity_verification";
import ProofCertificate, {
  proofCertificateSection,
  shouldMigrateToProofCertificate,
} from "common/notary/profile_wizard/section/digital_certificate/proof";
import OnboardingOverview, { ProfileReviewSteps } from "common/notary/onboarding/overview";
import { useOnboardingV2 } from "util/feature_detection";
import { proofAcademySection } from "common/notary/profile_wizard/section/proof_academy";
import { CertificateMigrationStatus } from "graphql_globals";

import type {
  BusinessNotaryProfileOnboardingStep as User,
  BusinessNotaryProfileOnboardingStep_notaryProfile as NotaryProfile,
} from "./index_fragment.graphql";
import type { Step } from "../sidebar";

type StepProps = {
  user: User;
  activeStep?: Section;
  onNext: MaybeUpdateUserFn;
  onBack?: () => void;
  renderUnknownStep: () => ReactNode;
  refetch: () => void;
  sections: readonly Step[];
};

type MaybeSection =
  | ReturnType<typeof personalInfoSection>
  | ReturnType<typeof commissionDetailsSection>
  | ReturnType<typeof certificateSection>
  | ReturnType<typeof insuranceDetailsSection>
  | ReturnType<typeof payoutSection>
  | ReturnType<typeof stateEducationSection>
  | ReturnType<typeof sigAndSealSection>
  | ReturnType<typeof identityVerificationSection>
  | ReturnType<typeof proofCertificateSection>
  | ReturnType<typeof profileReviewSection>
  | ReturnType<typeof proofAcademySection>;
type Section = Exclude<MaybeSection, false>;

function filterSections(maybeSection: MaybeSection): maybeSection is Section {
  return Boolean(maybeSection);
}

type ProfileReviewSectionId = "SubmitProfile" | "ProofAcademy" | "ProfileApproval";

type ProfileReviewSectionType = {
  id: ProfileReviewSectionId;
  completed: boolean;
  isActive: boolean;
};

function profileReviewSection(
  id: ProfileReviewSectionId,
  completed: boolean,
  activeSectionId?: string,
): ProfileReviewSectionType {
  return {
    id,
    completed,
    isActive: activeSectionId === id,
  };
}

export function useOnboardingSections(user: User, activeSectionId?: string) {
  const notaryProfile = user.notaryProfile!;
  const migrateExpiringNotaries = notaryProfile.usState.proofCertEnabled;
  const onboardingV2 = useOnboardingV2();
  const { sections, activeSection } = useMemo(() => {
    const lookup = new Set(notaryProfile.requirements);
    const sections: MaybeSection[] = [
      personalInfoSection(lookup, user),
      commissionDetailsSection(lookup, notaryProfile),
      insuranceDetailsSection(lookup, notaryProfile),
      certificateSection(lookup, notaryProfile, migrateExpiringNotaries),
      stateEducationSection(lookup, notaryProfile),
      sigAndSealSection(lookup, notaryProfile),
      payoutSection(user),
      identityVerificationSection(notaryProfile, migrateExpiringNotaries),
      proofCertificateSection(notaryProfile, migrateExpiringNotaries),
    ];

    if (onboardingV2) {
      const filteredSections = sections.filter(
        (section) => section && !ProfileReviewSteps.includes(section.id),
      );
      const finalStepCompleted = filteredSections.every((section) => section && section.completed);
      sections.push(profileReviewSection("SubmitProfile", finalStepCompleted, activeSectionId));
      sections.push(proofAcademySection(notaryProfile));
      sections.push(profileReviewSection("ProfileApproval", false, activeSectionId));
    }

    const processedSections = sections.filter(filterSections).map((section) =>
      Object.freeze({
        ...section,
        isActive: activeSectionId === section.id,
      }),
    );

    const activeSection = processedSections.find((section) => section.isActive);
    return { sections: Object.freeze(processedSections), activeSection };
  }, [activeSectionId, user, notaryProfile, onboardingV2]);

  return {
    sections,
    activeSection,
  };
}

function isProofCertDisabled(notaryProfile: NotaryProfile) {
  const migrateExpiringNotaries = notaryProfile.usState.proofCertEnabled;
  return !(
    notaryProfile.certificateMigrationStatus === CertificateMigrationStatus.PROOF_CERTIFICATE ||
    shouldMigrateToProofCertificate(
      migrateExpiringNotaries,
      notaryProfile.certExpiry,
      notaryProfile.certificateMigrationStatus,
    )
  );
}

function renderContent({
  activeStep,
  user,
  onNext,
  onBack,
  renderUnknownStep,
  refetch,
  sections,
}: StepProps) {
  switch (activeStep?.id) {
    case "PersonalInfo":
      return (
        <PersonalInformationSection
          user={user}
          onNext={onNext}
          renderFooter={(handleSubmit) => <Footer onSubmit={handleSubmit} workToCommit />}
        />
      );
    case "CommissionDetails":
      return (
        <CommissionDetails
          user={user}
          onNext={onNext}
          countyRequired={activeStep.countyRequired}
          notaryIdRequired={activeStep.notaryIdRequired}
          renderFooter={(handleSubmit) => (
            <Footer onBack={onBack} onSubmit={handleSubmit} workToCommit />
          )}
        />
      );
    case "InsuranceDetails":
      return (
        <InsuranceDetails
          user={user}
          onNext={onNext}
          renderFooter={(handleSubmit) => (
            <Footer onBack={onBack} onSubmit={handleSubmit} workToCommit />
          )}
        />
      );
    case "DigitalCertificate":
      return (
        <DigitalCertificate
          user={user}
          onNext={onNext}
          renderFooter={(handleSubmit) => (
            <Footer onBack={onBack} onSubmit={handleSubmit} workToCommit />
          )}
        />
      );
    case "SignatureAndSeal": {
      const hasNoPayoutSection = !sections.some((section) => section.id === "PayoutOptions");
      return (
        <SignatureAndSeal
          user={user}
          onNext={onNext}
          renderFooter={(handleSubmit) => (
            <Footer
              onBack={onBack}
              onSubmit={handleSubmit}
              workToCommit
              lastStep={isProofCertDisabled(user.notaryProfile!) && hasNoPayoutSection}
            />
          )}
        />
      );
    }
    case "StateEducation":
      return (
        <StateRequiredEducation
          user={user}
          onNext={onNext}
          proofRequired={activeStep.proofRequired}
          expirationDateRequired={activeStep.expirationDateRequired}
          renderFooter={(handleSubmit) => (
            <Footer onBack={onBack} onSubmit={handleSubmit} workToCommit />
          )}
        />
      );
    case "PayoutOptions":
      return (
        <PayoutOptions
          user={user}
          renderFooter={(disabled) => (
            <Footer
              onBack={onBack}
              onSubmit={onNext}
              disabled={disabled}
              workToCommit
              lastStep={isProofCertDisabled(user.notaryProfile!)}
            />
          )}
          refetch={refetch}
        />
      );
    case "IdentityVerification":
      return (
        <IdentityVerification
          user={user}
          renderFooter={(disabled) => (
            <Footer onBack={onBack} onSubmit={onNext} disabled={disabled} workToCommit />
          )}
        />
      );
    case "ProofCertificate":
      return (
        <ProofCertificate
          user={user}
          renderFooter={(disabled) => (
            <Footer onBack={onBack} onSubmit={onNext} disabled={disabled} workToCommit lastStep />
          )}
        />
      );
    case "SubmitProfile":
    case "ProofAcademy":
    case "ProfileApproval":
      return <OnboardingOverview steps={sections} notaryProfile={user.notaryProfile!} finished />;
    default:
      return renderUnknownStep();
  }
}

export const NotaryOnboardingStep = memo((props: StepProps) => (
  <div className="NotaryProfileWizardStep">{renderContent(props)}</div>
));
